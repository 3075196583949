import Vue from 'vue'
import axios from 'axios'
import { Toast } from 'vant';

Vue.use(Toast);
console.log(process.env.VUE_APP_BaseApi, 'process.env.VUE_APP_BaseApi')
const service = axios.create({
  baseURL: process.env.VUE_APP_BaseApi, // api base_url
  timeout: 9000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
      let data = error.response.data
      // const token = localStorage.getItem('Access-Token')
      switch (error.response.status) {
      case 403:
        Toast.fail('拒绝访问 403')
          break
      case 500:
        Toast.fail('登录失效 500')
        break
      case 404:
        Toast.fail('资源未找到 404')
        break
      case 504:
        Toast.fail('网络超时 504')
        break
      case 401:
        Toast.fail('未授权 401')
        break
      case 405:
        Toast.fail('请求方式错误 405')
        break
      default:
        Toast.fail(data.message)
        break
      }
  }
  return Promise.reject(error)
}

service.interceptors.request.use(config => {
  const token = localStorage.getItem('Access-Token')
  if (token) {
      config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

service.interceptors.response.use((response) => {
  return response.data
}, err)

export default service